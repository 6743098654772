import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

class TechStack extends React.Component {


  render() {
    return (
      <div className='pt-100'>
      <section className="binancestack dev-tech-stack exchange-stack">
        <div className="container">
          <div className="row table-content">
            <div className="col-md-12 col-lg-6">
              <h5 className="heading-h2"><span className='bluecolor'>Technologies Used</span> for Our White Label Crypto Wallet</h5>
              <p className="pharagraph">Our team consists of skilled cryptocurrency wallet developers who are experts at customizing the best crypto wallets using the latest tech stacks.
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
              <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.png"
                        alt="Technologies stack"
                        width={573}
                      />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default TechStack             